import React from 'react';
import styled from 'styled-components';
import { useSessionState } from '@beelineloans/cx-library/src/state/stateProvider';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { NumberedListWithImage, TextWithChecklistImg, SocialProof, TextAndImageV2, ReviewsWithSocial } from '@beelineloans/cx-library/src/components/layout/panels';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { Paragraph } from '@beelineloans/cx-library/src/components/typography';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';
import Hat from '@beelineloans/cx-library/src/images/confetti-hat-full.png';
import Homes from '@beelineloans/cx-library/src/images/investment-homes.png';
import Chat from '@beelineloans/cx-library/src/images/DSCR-chat.png';
import PhoneHeroInvest from '@beelineloans/cx-library/src/images/phone-hero-invest.png';
// import Box from '@beelineloans/cx-library/src/images/box.png';
// import FaqList from '@beelineloans/cx-library/src/components/layout/panels/FaqList';
import investorReviews from '@beelineloans/cx-library/src/utils/investor_reviews';
import FaqList from '@beelineloans/cx-library/src/components/layout/panels/FaqList';

const TemplateWrap = styled.div``;

// const AskButton = styled(ButtonEmail)`
//   margin-top: 40px;
// `;

const WTF = styled(TextAndImageV2)`
  .image-wrap h2 {
    display: none;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    .text-wrap h2,
    .text-wrap h3 {
      display: none;
    }
    .image-wrap h2 {
      display: block;
    }
  }
`;

const DSCR = () => {
  const [, dispatch] = useSessionState();
  const subHeadline = (
    <>
      Tailored online DSCR loan application.
      <br />
      Accurate approval and rates in quick time.
      <br />
      Get it done. Then onto the next one.
    </>
  );

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...SiteCopy.numberedList.TailoredFlowInvestor
    },
    {
      number: 2,
      noBorder: false,
      ...SiteCopy.numberedList.MoreOptionsDSCR
    },
    {
      number: 3,
      noBorder: false,
      ...SiteCopy.numberedList.CertaintyUpfrontDSCR
    },
    {
      number: 4,
      noBorder: false,
      ...SiteCopy.numberedList.FastStressFree
    }
  ];

  const onExit = () => {
    dispatch({
      type: CONST.ACTIONS.CONTACT_FORM_OPEN_EXIT_INTENT,
      contactForm: {
        open: true,
        type: 'investor',
        exitDone: true
      }
    });
  };

  return (
    <TemplateWrap>
      <Template
        onPageExit={onExit}
        headline="DSCR loans — the secret to growing your portfolio without personal income."
        subHeadline={subHeadline}
        headerProps={{
          PhoneImage: PhoneHeroInvest,
          options: {
            headerRatings: true,
            videoSupportingText: 'DSCR broke Adrian’s 10-year loan drought',
            videoId: CONST.VIDEOS.VOOMLY.ADRIAN,
            voomly: true
          }
        }}
      >
        <SEO
          path={CONST.LINKS.MAIN.INVESTORS.DSCR}
          title="Pain-free DSCR loans for your investment property"
          description="Grow your property portfolio without using your personal income with a simplified DSCR loan application. Accurate approval and rates in 15 mins."
          antiFlicker
        />
        <SocialProof dark />
        <TextAndImageV2
          backgroundColor={Theme.colours.background.nudeLight}
          h2="Wondering how these 23 year old prodigies own 20 properties?"
          image={{
            src: Hat,
            mobileWidth: '315px',
            desktopWidth: '286px',
            alignment: 'center'
          }}
          left
        >
          <Paragraph>The answer is DSCR.</Paragraph>
          <Paragraph>DSCR (debt service coverage ratio) loans are evaluated based on the property’s rental income, not the applicant’s personal income.</Paragraph>
          <Paragraph>So your portfolio’s growth isn’t restricted by your personal income and there’s no limit to the number of properties you can own.</Paragraph>
          <Paragraph>Approval is faster and easier too because there’s no painful personal income verification.</Paragraph>
          <Paragraph>You can close in an LLC with as little as 20% down — even using short term rental income.</Paragraph>
        </TextAndImageV2>
        <NumberedListWithImage
          sideHeading={
            <>
              Why Beeline for
              <br />
              DSCR loans?
            </>
          }
          items={numberedList}
        />
        <ReviewsWithSocial
          googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM}
          bbbRating={process.env.GATSBY_BBB_REVIEW_NUM}
          showRating
          title="These investors know what’s up..."
          showSideImage
          items={investorReviews}
          text={<></>}
        />

        <WTF
          backgroundColor={Theme.colours.background.cream}
          h2="What the heck is DSCR?"
          imgH2="What the heck is DSCR?"
          left
          image={{
            src: Chat,
            mobileWidth: '315px',
            desktopWidth: '538px',
            alignment: 'left'
          }}
        >
          <Paragraph>You can only grow your portfolio so much using personal income. So why not spread your wings and get into the world of DSCR?</Paragraph>
          <Paragraph>What is DSCR you may be asking?</Paragraph>
          <Paragraph>DSCR stands for Debt Service Coverage Ratio.</Paragraph>
          <Paragraph>Eligibility for DSCR loans is based on verifying the rental property’s income, not your personal income, tax returns and pay stubs.</Paragraph>
          <Paragraph>
            Conventional loans rely on your income to support extra loan payments — so after a couple of properties, you max out your ability to borrow (unless you earn
            squillions).
          </Paragraph>
          <Paragraph>That’s where DSCR loans come in.</Paragraph>
          <Paragraph>They transform newbies into cigar wielding tycoons. </Paragraph>
          <Paragraph>
            But while DSCR loans have advantages, rates are slightly higher so if your income isn’t maxed with other properties, a conventional loan might be better. We can help
            you decide.
          </Paragraph>
        </WTF>
        <TextWithChecklistImg
          backgroundColor={Theme.colours.background.white}
          heading="What you need to access the world of DSCR."
          imageAlignment={{
            title: 'DSCR requirements',
            mobile: 'bottom',
            desktop: 'center'
          }}
        >
          <Paragraph>A credit score of at least 580.</Paragraph>
          <Paragraph>At least 12 months’ history of paying either mortgage or rent payments.</Paragraph>
          <Paragraph>Total annual rent should cover your principal, interest, insurance, property taxes and HOA fees (if applicable).</Paragraph>
          <Paragraph>A minimum of 20% downpayment.</Paragraph>
          <Paragraph>Reserves to cover 6 months’ worth of payments.</Paragraph>
          <Paragraph>Long and short term rentals like Airbnb are ok.</Paragraph>
        </TextWithChecklistImg>

        <FaqList title="Investment property loans FAQ" />
        {/* <TextAndImageV2
          h2={
            <>
              Got an outside of the box case?
              <br />
              No problem.
            </>
          }
          image={{
            src: Box,
            mobileWidth: '315px',
            title: 'Out of the box DSCR loans',
            desktopWidth: '483px',
            alignment: 'center'
          }}
          left
        >
          <Paragraph>
            Maybe you’ve got the kind of situation other lenders put in the ‘too hard’ basket.
            <br />
            <br />
            So the thought of going through an application gives you hives — you just want to know if you can get a loan, or not.
            <br />
            <br />
            Good news! We embrace the quirks.
            <br />
            <br />
            We’ve got dozens of different loan types, and Loan Guides who aren’t afraid to use them.
            <br />
            <br />
            Get in touch with a Loan Guide who’ll go through their bag of tricks to find a loan that works for you.
          </Paragraph>
          <AskButton type="investor" asButton>
            Ask a Loan Guide
          </AskButton>
        </TextAndImageV2>
        <FaqList /> */}
        <TextAndImageV2
          backgroundColor={Theme.colours.background.turquoise}
          h2="DSCR loans suit tycoons and investors just starting out."
          image={{
            src: Homes,
            mobileWidth: '315px',
            desktopWidth: '437px',
            alignment: 'right',
            title: 'DSCR calculation'
          }}
        >
          <Paragraph>We love seeing people taking steps towards financial freedom.</Paragraph>
          <Paragraph>Whether that’s smoking fat obnoxious cigars on a yacht or just working a little less hard — we’ll leave that up to you.</Paragraph>
          <Paragraph>So let’s get the loan out of the way — so you can watch that rent roll in and move onto the next one!</Paragraph>
        </TextAndImageV2>
        {/* <ReviewsWithSocial
          googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM}
          bbbRating={process.env.GATSBY_BBB_REVIEW_NUM}
          showRating={false}
          title="These investors know what’s up..."
          text="See why other savvy investors trust us for speedy, pain-free loans."
          showSideImage
          items={investorReviews}
        /> */}
      </Template>
    </TemplateWrap>
  );
};

DSCR.propTypes = {};

DSCR.defaultProps = {};

export default DSCR;
